<template>
  <div>
    <StatFilter PERIOD="daily" :STORE_OPTS="branch_list" @query="onQueryBI" v-if="!loading.branch" />
    <CRow v-if="load_done">
      <CCol md="6" lg="3">
        <CardSimpleNumber :header="$t('cloud.title.occupancy_visitor_count')" :text="`${stats_visitor_total}`">
          <h4 :class="{'text-success':todayVisitIncrease >= 0,'text-danger':todayVisitIncrease<0}">
            <svgicon :name="todayVisitIncrease >= 0 ? 'arrow_drop_up':'arrow_drop_down'" width="23" height="23" />
            <span>{{Math.sqrt(todayVisitIncrease*todayVisitIncrease)}}</span>
          </h4>
        </CardSimpleNumber>
      </CCol>
      <CCol md="6" lg="3">
        <CardSimpleNumber :header="$t('cloud.title.occupancy_average')" :text="`${stats_occupancy_avg}`">
          <h4 :class="{'text-success':avgOccupancyIncrease >= 0,'text-danger':avgOccupancyIncrease<0}">
            <svgicon :name="avgOccupancyIncrease >= 0 ? 'arrow_drop_up':'arrow_drop_down'" width="23" height="23" />
            <span>{{avgOccupancyIncrease}}%</span>
          </h4>
        </CardSimpleNumber>
      </CCol>
      <CCol md="6" lg="3">
        <CardSimpleNumber :header="$t('cloud.title.occupancy_max')" :text="`${stats_occupancy_max}`">
          <h4 class="text-secondary">
            {{stats_occupancy_max_time}}
          </h4>
        </CardSimpleNumber>
      </CCol>
      <CCol md="6" lg="3">
        <CardSimpleNumber :header="$t('cloud.title.occupancy_trigger_count')" :text="`${stats_occupancy_triggered}`">
          <h4 :class="{'text-success':triggerIncrease >= 0,'text-danger':triggerIncrease<0}">
            <svgicon :name="triggerIncrease >= 0 ? 'arrow_drop_up':'arrow_drop_down'" width="23" height="23" />
            <span>{{triggerIncrease}}%</span>
          </h4>
        </CardSimpleNumber>
      </CCol>
      <CCol md="12" lg="6">
        <CardLineCharts
          :title="$t('cloud.title.occupancy_count')"
          :stats="[stats_occupancy_counts, stats_occupancy_limits]"
          :labels="[$t('cloud.title.day_today'), $t('cloud.title.occupancy_limit')]"
          :colors="['#658e44', '#d0234c']"
          :bgColors="['#658e44','none']"
          :lineStyle="['line','dash']"
          :legend="false"
        />
      </CCol>
      <CCol md="12" lg="6">
        <CardLineCharts
          :title="$t('cloud.title.occupancy_inout')"
          :stats="[stats_occupancy_in, stats_occupancy_out]"
          :labels="[$t('cloud.title.occupancy_in'), $t('cloud.title.occupancy_out')]"
          :colors="['#355ed4', '#b0534c']"
          :bgColors="['#355ed4','#b0534c']"
        />
      </CCol>
    </CRow>
    <div v-loading="!load_done" v-if="!load_done" />
  </div>
</template>

<script>
import axios from 'axios'
import qs from 'qs'
import StatFilter from './StatFilter.vue'
import CardSimpleNumber from '@/components/cards/SimpleNumber.vue'
// import CardBarCharts from '@/components/cards/BarCharts.vue'
import CardLineCharts from '@/components/cards/LineCharts.vue'

export default {
  name: 'RetailStoreOccupancyAnalytics',

  components: {
    StatFilter,
    CardSimpleNumber,
    // CardBarCharts,
    CardLineCharts
  },

  data() {
    return {
      load_done: false,
      loading: {
        branch: true,
        visitor: true
      },
      filters: null,
      branch_list: [],
      stats_visitor_total: 0,
      stats_visitor_total_yesterday: 0,
      stats_occupancy_avg: 0,
      stats_occupancy_avg_yesterday: 0,
      stats_occupancy_max: 0,
      stats_occupancy_max_time: null,
      stats_occupancy_triggered: 0,
      stats_occupancy_triggered_yesterday: 0,
      stats_occupancy_counts: [],
      stats_occupancy_limits: [],
      stats_occupancy_in: [],
      stats_occupancy_out: []
    }
  },
  computed: {
    todayVisitIncrease() {
      return (this.stats_visitor_total - this.stats_visitor_total_yesterday);
    },
    avgOccupancyIncrease() {
      let inc_percent = Math.round((this.stats_occupancy_avg - this.stats_occupancy_avg_yesterday) * 100 / Math.max(this.stats_occupancy_avg_yesterday, 1) * 100);
      inc_percent /= 100;
      return inc_percent;
    },
    triggerIncrease() {
      return 0;
    }
  },
  mounted() {
    this.initFetch();
  },
  methods: {
    initFetch() {
      this.getBranchList();
    },
    getBranchList() {
      let query_string = '';
      const params = {
        // sort: 'created_at',
        // order: 'desc',
        // search: this.searchText,
        ipp: 500, // FIXME. 난중에 하자...
        page: 1,
        opt: 'branch',
        geo_info: 0
      };
      if (params) {
        query_string = '?' + qs.stringify(params)
      }
      this.loading.branch = true;
      axios.get(`/api/bi/stores/${query_string}`)
        .then(result => {
          this.branch_list = result.data.list;
          let capacity = 0;
          if (!this.filters || !this.filters.branch) {
            this.branch_list.map(el => {
              capacity += el.capacity;
            });
          } else {
            const branch = this.branch_list.find(el => el.id === this.filters.branch);
            capacity = branch.capacity;
          }
          this.stats_occupancy_limits = [];
          for (const el of this.stats_occupancy_counts) {
            this.stats_occupancy_limits.push({
              value:capacity,
              x:el.created_at
            });
          }
        })
        .catch(error => {
          console.error(error);
        })
        .finally(() => {
          this.loading.branch = false;
          this.checkLoading();
        })
    },
    onQueryBI(stat_filter) {
      this.load_done = false;
      this.filters = stat_filter;
      if (this.filters && this.filters.date) {
        this.statOccupancy();
      }
    },
    buildBIQs(date_before) {
      let query_string = '';
      const params = {};
      if (this.filters) {
        params.branch = this.filters.branch;
        if (this.filters.date) {
          this.filters.date.setHours(0);
          this.filters.date.setMinutes(0);
          this.filters.date.setSeconds(0);
          this.filters.date.setMilliseconds(0);
          params.sts = this.filters.date.getTime();
          params.ets = params.sts + (1000 * 60 * 60 * 24);

          if (date_before) {
            const delta = date_before * 24 * 60 * 60 * 1000;
            params.sts = params.sts - delta;
            params.ets = params.ets - delta;
          }
        }
      }
      if (params) {
        query_string = '?' + qs.stringify(params);
      }
      return query_string;
    },
    statOccupancy() {
      const query_string = this.buildBIQs();
      const query_string_yesterday = this.buildBIQs(1);
      let loading_today = true;
      let loading_yesterday = true;

      this.loading.visitor = true;
      axios.get(`/api/bi/statistics/occupancy/${query_string}`)
        .then(result => {
          // 방문자 총수
          this.stats_visitor_total = 0;
          for (const d of result.data) {
            this.stats_visitor_total += d.inc_sum;
          }
          // 평균 재실 인원 수
          let total_sum = 0;
          let cnt = 0;
          for (const d of result.data) {
            const ts = new Date(d.created_at);
            // TODO. 운영시간 설정하고 체크하자. 임시로 7시라고 하자.
            if (ts.getHours() < 7 || ts.getTime() > Date.now()) continue;
            cnt++;
            total_sum += d.abs;
          }
          this.stats_occupancy_avg = Math.round(total_sum / Math.max(cnt,1));
          // 최대 재실 인원 수 및 시간대
          this.stats_occupancy_max = 0;
          result.data.map(el => {
            if (this.stats_occupancy_max < el.abs) {
              this.stats_occupancy_max = el.abs;
              this.stats_occupancy_max_time = this.$utility.GetDateTimeStr("$HH:00", new Date(el.created_at));
            }
          })
          // 재실인원 추이
          this.stats_occupancy_counts = [];
          for (const el of result.data) {
            this.stats_occupancy_counts.push({
              value:Math.max(el.abs, 0),
              x:el.created_at
            });
          }
          let capacity = 0;
          if (!this.filters || !this.filters.branch) {
            this.branch_list.map(el => {
              capacity += el.capacity;
            });
          } else {
            const store = this.branch_list.find(el => el.branch_connected.id === this.filters.branch);
            capacity = store.capacity;
          }
          this.stats_occupancy_limits = [];
          for (const el of result.data) {
            this.stats_occupancy_limits.push({
              value:capacity,
              x:el.created_at
            });
          }
          // 입장 및 퇴장 수
          this.stats_occupancy_in = [];
          this.stats_occupancy_out = [];
          for (const el of result.data) {
            this.stats_occupancy_in.push({
              value:el.inc_sum,
              x:el.created_at
            });
            this.stats_occupancy_out.push({
              value:el.dec_sum,
              x:el.created_at
            });
          }
        })
        .catch(error => {
          console.error(error);
        })
        .finally(() => {
          loading_today = false;
          if (!loading_yesterday) {
            this.loading.visitor = false;
            this.checkLoading();
          }
        })
      axios.get(`/api/bi/statistics/occupancy/${query_string_yesterday}`)
        .then(result => {
          this.stats_visitor_total_yesterday = 0;
          for (const d of result.data) {
            this.stats_visitor_total_yesterday += d.inc_sum;
          }
          // 평균 재실 인원 수
          let total_sum = 0;
          let cnt = 0;
          for (const d of result.data) {
            const ts = new Date(d.created_at);
            // TODO. 운영시간 설정하고 체크하자. 임시로 7시라고 하자.
            if (ts.getHours() < 7) continue;
            cnt++;
            total_sum += d.abs;
          }
          this.stats_occupancy_avg_yesterday = Math.round(total_sum / Math.max(cnt,1));
        })
        .catch(error => {
          console.error(error);
        })
        .finally(() => {
          loading_yesterday = false;
          if (!loading_today) {
            this.loading.visitor = false;
            this.checkLoading();
          }
        })
    },
    checkLoading() {
      if (!this.loading.branch && !this.loading.visitor) {
        setTimeout(() => {
          this.load_done = true;
        }, 100);
      }
    }
  }
}
</script>